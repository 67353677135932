import 'pure-react-carousel/dist/react-carousel.es.css';

import Amplify from 'aws-amplify';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import React from 'react';
import ReactWOW from 'react-wow';
import Cookies from 'universal-cookie';

import config from '../../src/aws-exports';
import Layout from '../components/layout';
import SEO from '../components/SEO'
Amplify.configure(config)
var EmbedVideo = function (props) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
       <video
         loop
         muted
         autoplay
         playsinline
         src="/breathe-reel.mp4"
         className="bgVideo"
         style="z-index: -1; max-width: 100%; height: calc(100vh + 126px); object-fit: cover; width: 100%; pointer-events: none; filter: brightness(70%);"
       />,
     `,
      }}
    ></div>
  )
}
export const query = graphql`
  query HomeQuery {
    biqMobile: allFile(filter: { relativePath: { eq: "mockups/biq.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    biqBackground: allFile(
      filter: { relativePath: { eq: "mockups/biq-background.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    partners: allFile(filter: { relativeDirectory: { eq: "partners/home" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    methodImage: allFile(filter: { relativePath: { eq: "mockups/home-mockup.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    mediaIcons: allFile(filter: { relativeDirectory: { eq: "media" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
    console.log(this.props.data.biqBackground.edges[0])
  }

  componentDidMount() {
    window.addEventListener('scroll', e => {
      const banner = document.querySelector('#homeBanner')
      let value =
        1 +
        window.scrollY / (-window.screen.height + window.screen.height * 0.25)
      if (banner != null) {
        banner.style.opacity = value
      }
    })
  }

  submitEmail = event => {
    // console.log(process.env.IS_LIVE);
    if (process.env.IS_LIVE === 'true') {
      const cookies = new Cookies()
      var obj = {}
      obj.fields = []

      var emailObj = {}
      emailObj.name = 'email'
      emailObj.value = this.state.email

      obj.fields.push(emailObj)

      obj.context = {}
      // obj.context.hutk = this.getCookie('hubspotutk');
      obj.context.hutk = cookies.get('hubspotutk')
      obj.context.pageUri = 'www.thebreathingiq.com'
      obj.context.pageName = 'Home Page'
      console.log(obj);
      fetch(
        'https://api.hsforms.com/submissions/v3/integration/submit/8733114/6443243d-7fe4-4fcb-86ab-f719c356b50e',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(obj),
        }
      )
    }
  }

  handleChange = event => {
    const label = event.target.name
    const value = event.target.value
    this.setState({ [label]: value })
  }

  render() {
    const vogueLogo = this.props.data.mediaIcons.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('vogue.png') !== -1
    )[0].node.childImageSharp.fluid
    const kellyRyanLogo = this.props.data.mediaIcons.edges.filter(
      edge =>
        edge.node.childImageSharp.fluid.src.indexOf('kellyryan.png') !== -1
    )[0].node.childImageSharp.fluid

    const timeLogo = this.props.data.mediaIcons.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('time.png') !== -1
    )[0].node.childImageSharp.fluid

    const gqLogo = this.props.data.mediaIcons.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('gq.png') !== -1
    )[0].node.childImageSharp.fluid

    const shapeLogo = this.props.data.mediaIcons.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('shape.png') !== -1
    )[0].node.childImageSharp.fluid

    const oprahLogo = this.props.data.mediaIcons.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('oprah.png') !== -1
    )[0].node.childImageSharp.fluid
    const methodImage = this.props.data.methodImage.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('home-mockup.png') !== -1
    )[0].node.childImageSharp.fluid
    return (
      <Layout>
        <SEO />
        <section id="homeBanner" className="demo">
          <EmbedVideo />
          <div className="banner-content">
            <div
              className="flex flex-col justify-end"
            >
              <ReactWOW animation="fadeIn" delay="1s">
                <div className="flex flex-col place-items-center" style={{ height: '51vh' }}>
                  <h1>Helping the world catch their breath</h1>
                  <div className="text-center biq-text-xl">
                    <br />
                  </div>
                  <ReactWOW animation="fadeIn" delay="1.4s">
                    <div className="flex mt-14">
                      <Link to="/biq" className="btn-light-outline mt-4">
                        Find out your Breathing IQ
                      </Link>
                    </div>
                  </ReactWOW>
                </div>
              </ReactWOW>
            </div>
            {/* <div className="flex flex-col justify-end">
              <ReactWOW animation="zoomIn" delay="4s">
                <div className="flex">
                  <AnchorLink
                    to="/#homeContent"
                    className="flex flex-col place-items-center"
                  >
                    <div className="flex my-4 biq-text-lg font-extrabold">We Can Help!</div>
                    <div className="flex bounce">
                      <DownIcon height="50px" width="50px" />
                    </div>
                  </AnchorLink>
                </div>
              </ReactWOW>
              <div className="flex h-10"></div>
            </div> */}
          </div>
        </section>
        {/* <section id="homeContent" className="section-padding">
          <div className="container"> */}



            {/* <ReactWOW animation="zoomIn">
              <h2
                className="pb-12 biq-font-semibold"
              >
                "How well you breathe is the best indicator of how healthy you
                are and how long you’ll live"
              </h2>
            </ReactWOW> */}


            {/* <ReactWOW animation="zoomIn" delay="0.4s">
              <div className="flex justify-self-center place-self-center">
                <AnchorLink
                  to="/#topicContent"
                  className="flex flex-col place-items-center"
                >
                  <div className="flex my-4 biq-text-xl font-medium text-center biq-text-prim">
                    Breathing exercises can help with...
                  </div>
                </AnchorLink>
              </div>
            </ReactWOW> */}


            {/* ICONS */}
            {/* <ReactWOW animation="fadeIn" delay="0.8s">
              <h2 className="pt-12">We Can Help With...</h2>
            </ReactWOW> */}



            {/* <div
              id="topicContent"
              className="flex flex-row flex-wrap mx-0 xl:mx-12 py-12 overflow-hidden"
            >
              <ReactWOW animation="fadeInRight" delay="0.6s">
                <Link to="/topics/backpain" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="topic-icon">
                    <BackPainIcon fill="#3c5164" height="100%" width="100%" />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">Back Pain</h3>
                </Link>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="0.8s">
                <Link to="/topics/covid" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="topic-icon">
                    <VirusIcon fill="#3c5164" height="100%" width="100%" />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">Covid</h3>
                </Link>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="1s">
                <Link to="/topics/respiratory" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="topic-icon">
                    <LungsIcon fill="#3c5164" height="100%" width="100%" />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">
                    Respiratory Health
                  </h3>
                </Link>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="0.6s">
                <Link to="/topics/digestive" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="justify-between topic-icon">
                    <DigestiveIcon fill="#3c5164" height="100%" width="100%" />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">Digestive Health</h3>
                </Link>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="0.8s">
                <Link to="/topics/kids" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="topic-icon">
                    <KidsIcon fill="#3c5164" height="100%" width="100%" />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">Kids</h3>
                </Link>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="1s">
                <Link to="/topics/mental" className="topic-container flex flex-col p-2 xl:p-12 w-6/12 xl:w-4/12 justify-center place-items-center">
                  <div className="topic-icon">
                    <MentalHealthIcon
                      fill="#3c5164"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <h3 className="mx-auto text-center biq-text-prim mt-6">Mental Health</h3>
                </Link>
              </ReactWOW>
            </div> */}


            {/* <div className="flex flex-col place-self-center justify-end mt-8 mb-6">
              <ReactWOW animation="zoomIn" delay="0.4s">
                <div className="flex">
                  <AnchorLink
                    to="/#methodContent"
                    className="flex flex-col place-items-center"
                  >
                    <div className="flex my-4 biq-text-lg xl:biq-text-xl font-medium text-center">
                      Don't know where to start? <br /> Don't worry. We've got you.
                    </div>
                    <div className="flex bounce">
                      <DownIcon height="50px" width="50px" />
                    </div>
                  </AnchorLink>
                </div>
              </ReactWOW>
            </div> */}


          {/* </div>
        </section> */}

        {/* <section
          id="methodContent"
          className="section-padding biq-bg-gray biq-text-black"
        >
          <div className="container">
            <ReactWOW animation="fadeIn" delay="0.4s">
              <h2 className="biq-font-semibold">How It Works</h2>
              <h3 className="flex my-6 text-center biq-text-prim justify-center">
                  You can't change what you don't measure
                </h3>
            </ReactWOW>
            <div className="flex flex-row flex-wrap w-full mt-12">
              <div className="flex w-full md:w-1/2 justify-center">
              <ReactWOW animation="slideInLeft" delay="0.2s">
                <div className="w-10/12 md:w-10/12">
                  <Img fluid={methodImage} />
                </div>

              </ReactWOW>
              </div>
              <div className="flex flex-col w-full md:w-1/2 xl:px-20">
                <ReactWOW animation="fadeIn" delay="0.4s">

                <div className="flex flex-row py-8">
                  <div className="flex justify-center place-items-center mr-8">
                    <div className="flex">
                      <CalculatorIcon fill="#3c5164" height="70px" width="70px" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex biq-text-xl biq-font-semibold">
                    Score
                    </div>
                    <div className="flex biq-text-lg">
                    Establish a baseline with our easy to use screening tool that allows you to assess if you are really taking a deep breath.
                    </div>
                  </div>
                </div>
                </ReactWOW>
                <ReactWOW animation="fadeIn" delay="0.6s">

                <div className="flex flex-row py-8">
                <div className="flex justify-center place-items-center mr-8">
                    <div className="flex">
                      <BookIcon fill="#3c5164" height="70px" width="70px" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex biq-text-xl biq-font-semibold">
                    Finesse
                    
                    </div>
                    <div className="flex biq-text-lg">
                    Make sure you are using the right muscles
                    </div>
                  </div>
                </div>
                </ReactWOW>
                <ReactWOW animation="fadeIn" delay="0.8s">

                <div className="flex flex-row py-8">
                  <div className="flex justify-center place-items-center mr-8">
                    <div className="flex">
                      <BodyIcon fill="#3c5164" height="70px" width="70px" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex biq-text-xl biq-font-semibold">
                    Strengthen
                    </div>
                    <div className="flex biq-text-lg">
                    The best way to have “strong lungs” is to strengthen the muscles that make them fill and empty
                    </div>
                  </div>
                </div>
                </ReactWOW>
                <div className="flex justify-center mt-6 xl:mt-20">
                  <Link to="/biq" className="btn-sec biq-text-white">
                      Take the BIQ
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}


        <section id="testimonialContent" className="section-padding">
          <div className="container">
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={110}
              totalSlides={6}
              infinite={true}
              interval={5000}
              isPlaying={true}
            >
              <Slider className="h-full xl:h-72" style={{ maxHeight: '424px' }}>
                {/* OPRAH */}
                <Slide index={0}>
                  <AnchorLink
                    to="/media/#oprahContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      <div className="w-1/2">
                        <Img fluid={oprahLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      Feel like you haven’t taken a deep breath in eons? That’s
                      because you probably haven’t, says Belisa Vranich,
                      clinical psychologist and author of Breathe: 14 Days to
                      Oxygenating, Recharging, and Fueling Your Body & Brain,
                      who’s taught everyone from Fortune 500 employees to SWAT
                      teams how to breathe for stress relief and endurance.
                    </div>
                  </AnchorLink>
                </Slide>
                {/* KELLY & RYAN */}
                <Slide index={1}>
                  <AnchorLink
                    to="/media/#kellyRyanContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      <div className="w-1/2">
                        <Img fluid={kellyRyanLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      Kelly and Ryan learn breathing techniques on this episode
                      of JanNEWary. Dr. Belisa explains breathing muscles, Kelly
                      talks wardrobe malfunction, and both get to do Cat-Cow on
                      stage.
                    </div>
                  </AnchorLink>
                </Slide>
                {/* TIME */}
                <Slide index={2}>
                  <AnchorLink
                    to="/media/#timeContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      {/* IMAGE */}
                      <div className="w-1/2">
                        <Img fluid={timeLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      {/* TEXT */}
                      For something so basic, we sure do get it wrong sometimes.
                      "Breathing is the most important thing we do, but the
                      thing we pay the least amount of attention to," says
                      Belisa Vranich, a clinical psychologist and the author of
                      Breathe.
                    </div>
                  </AnchorLink>
                </Slide>
                {/* GQ */}
                <Slide index={3}>
                  <AnchorLink
                    to="/media/#gqContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      {/* IMAGE */}
                      <div className="w-1/2">
                        <Img fluid={gqLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      {/* TEXT */}
                      Are you doing anything right now? Of course you’re
                      not—you’re reading wellness articles on the Internet. Why
                      don’t you take a moment out of your rigorous afternoon of
                      self-improvement and indulge in a long, deep breath?
                      Inhale for six whole seconds, expel that air, and record,
                      very specifically, what your body is doing while this is
                      happening.
                    </div>
                  </AnchorLink>
                </Slide>
                {/* VOGUE */}
                <Slide index={4}>
                  <AnchorLink
                    to="/media/#vogueContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      {/* IMAGE */}
                      <div className="w-1/2">
                        <Img fluid={vogueLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      {/* TEXT */}
                      There are a lot of new-age wellness techniques touted that
                      make even the least cynical among us roll our eyes – yes,
                      we’re looking at you jade eggs. But on the flip-side, in
                      times of unprecedented uncertainty, there are useful, yet
                      often overlooked approaches that deserve more of the
                      limelight. Breathwork is one of them.
                    </div>
                  </AnchorLink>
                </Slide>
                {/* SHAPE MAG */}
                <Slide index={5}>
                  <AnchorLink
                    to="/media/#shapeContent"
                    className="flex flex-row flex-wrap"
                  >
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center">
                      {/* IMAGE */}
                      <div className="w-1/2">
                        <Img fluid={shapeLogo} />
                      </div>
                    </div>
                    <div className="flex w-full xl:w-1/2 place-items-center justify-center px-4">
                      {/* TEXT */}
                      Peel away stress. Achieve inner peace during your morning
                      beauty routine.
                    </div>
                  </AnchorLink>
                </Slide>
              </Slider>
              <div className="w-full flex flex-row flex-wrap justify-center xl:mt-8">
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={0}
                >
                  <Img fluid={oprahLogo} />
                </Dot>
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={1}
                >
                  <Img fluid={kellyRyanLogo} />
                </Dot>
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={2}
                >
                  <Img fluid={timeLogo} />
                </Dot>
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={3}
                >
                  <Img fluid={gqLogo} />
                </Dot>
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={4}
                >
                  <Img fluid={vogueLogo} />
                </Dot>
                <Dot
                  className="p-4 xl:p-8 w-4/12 xl:w-2/12 media-icon"
                  slide={5}
                >
                  <Img fluid={shapeLogo} />
                </Dot>
              </div>
            </CarouselProvider>
          </div>
        </section>
        {/* <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
        >
          <div className="flex absolute">
          </div>
          <div className="container">
            <div className="flex flex-row flex-wrap my-24">
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section> */}
        <section
          id="teamContent"
          className="section-padding biq-bg-gray biq-text-black"
        // style={{
        //   backgroundImage: 'url(/partner-bg.jpg)',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'left',
        //   backgroundSize: 'contain',
        //   backgroundColor: 'white',
        // }}
        >
          {/* BACKGROUND TEXT */}
          {/* <div className="partner-bg">
            <div className="partner-bg-text">Partner</div>
            <div className="partner-bg-text">with</div>
            <div className="partner-bg-text">Us</div>
          </div> */}
          <div className="partner-content">
            {/* <div className="partner-content-text">Corporate Wellness</div>
            <div className="partner-content-text">First Responders</div>
            <div className="partner-content-text">Teachers & Coaches</div> */}
            <h2 className="biq-font-semibold">Partner With Us</h2>
            <Link
              to="/contact"
              className="btn-sec-outline partner-content-text mt-10"
            >
              Let's Get To Work
            </Link>
          </div>
          {/* <div>
            <div className="flex mt-8 place-self-center">
              <button className="btn-light-outline">Contact Us</button>
            </div>
          </div> */}
        </section>
        {/* <div className="flex flex-row flex-wrap mt-8">
              <div className="flex flex-col p-5 w-1/2 justify-center place-items-center">
                <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.partners.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div>
                <span className="text-lg xl:text-xl text-center p-4">
                  Medical Professionals
                </span>
              </div>
              <div className="flex flex-col p-5 w-1/2 justify-center place-items-center">
                <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.partners.edges[3].node.childImageSharp
                        .fluid
                    }
                  />
                </div>
                <span className="text-lg xl:text-xl text-center p-4">
                  Teachers & Coaches
                </span>
              </div>
              <div className="flex flex-col p-5 w-1/2 justify-center place-items-center">
                <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.partners.edges[2].node.childImageSharp
                        .fluid
                    }
                  />
                </div>
                <span className="text-lg xl:text-xl text-center p-4">
                  Corporate Wellness
                </span>
              </div>
              <div className="flex flex-col p-5 w-1/2 justify-center place-items-center">
                <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.partners.edges[1].node.childImageSharp
                        .fluid
                    }
                  />
                </div>
                <span className="text-lg xl:text-xl text-center p-4">
                  First Responders
                </span>
              </div>
            </div> */}
        {/* <section id="teamContent" className="section-padding">
          <div className="container">
            <h1>Insert About us here</h1>
          </div>
        </section> */}
        <section
          id="subscribeBanner"
          className="section-padding biq-bg-white biq-text-prim"
        >
          <div className="container">
            <h2 className="biq-font-semibold">Breathing Intelligence</h2>
            <h3 className="flex mt-4">
              This site has the most practical info on breathing and respiratory
              health for parents, schools, and medical professionals.
            </h3>
            <div className="flex flex-col place-items-center justify-center w-full mt-4 py-4">
              <div className="flex flex-row w-full justify-center mt-3">
                <input
                  className="biq-text-black flex rounded-full px-3 mx-2 w-2/3 xl:w-1/2 border-solid border-2"
                  type="text"
                  name="email"
                  placeholder="Enter Email Address"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <button
                  value="Submit"
                  className="btn-sec"
                  onClick={this.submitEmail}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <EmbedVideo/> */}
        {/* <>
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col w-full xl:w-6/12 place-items-center justify-center">
              <span className="text-5xl">Coming Soon</span>
              <span className="mt-10 text-lg">Our website is under construction</span>
            </div>
            <div className="flex w-full xl:w-6/12 place-items-center justify-center">
              <PlanningImg />
            </div>
          </div>
        </> */}
        {/* <div className="w-full z-50 bg-white">
          <h1>BreathingIQ</h1>
          <h2>Coming Soon</h2>
          <p>Our website is under construction</p>
          <div className="flex w-full justify-between">
            <div className="flex">
              <Link to="/app/signup"><button className="inline-flex items-center justify-center px-4 py-2 text-base leading-5 rounded-md border font-medium shadow-sm transition ease-in-out duration-150 focus:outline-none focus:shadow-outline bg-white border-gray-300 text-gray-700">Sign Up</button></Link>
            </div>
            <div className="flex">
              <Link to="/app/signup"><button className="inline-flex items-center justify-center px-4 py-2 text-base leading-5 rounded-md border font-medium shadow-sm transition ease-in-out duration-150 focus:outline-none focus:shadow-outline bg-white border-gray-300 text-gray-700">Sign In</button></Link>
            </div>
            <div className="flex">
              <Link to="/app/signup"><button className="inline-flex items-center justify-center px-4 py-2 text-base leading-5 rounded-md border font-medium shadow-sm transition ease-in-out duration-150 focus:outline-none focus:shadow-outline bg-white border-gray-300 text-gray-700">Home</button></Link>
            </div>
            <div className="flex">
              <Link to="/app/signup"><button className="inline-flex items-center justify-center px-4 py-2 text-base leading-5 rounded-md border font-medium shadow-sm transition ease-in-out duration-150 focus:outline-none focus:shadow-outline bg-white border-gray-300 text-gray-700">Your Profile</button></Link>
            </div>

          </div>
        </div> */}
        {/* <div className="hero-image" style={{marginTop: '96.19px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage.childImageSharp.fluid.src})`, height: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative'}}>
          <div className="hero-text" style={{textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: "black"}}>
            <h1>Coming Soon</h1>
            <p>Our website is under construction</p>
          </div>
        </div> */}
        {/* <Img fluid={heroImage.childImageSharp.fluid}/> */}
      </Layout>
    )
  }
}

export default IndexPage
